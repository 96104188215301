import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Density`}</h1>
    <ul>
      <li parentName="ul">{`An easy way to think of this is what % of the total matrix is filled in`}</li>
      <li parentName="ul">{`e.g. 100 comments, 100 people, 10,000 opportunities to vote, 2000 votes, 20% dense.`}</li>
      <li parentName="ul">{`inverse of 'sparse' and 'sparsity'`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      